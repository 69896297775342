import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import Icon from 'components/icon'
import Post from 'templates/post'
import Meta from 'components/meta'
import Layout from 'components/layout'

import logo from '../../content/images/logo.png'

const BlogIndex = ({ data, location }) => {
  const posts = get(data, 'remark.posts')
  return (
    <Layout location={location}>
      <Meta site={get(data, 'site.meta')} />
      <div className="logo-side">
        <div>
          <img src={logo} alt="" />
        </div>
      </div>
      <div className="col-md-6 offset-md-6 mt-3 mt-md-5 pt-4 pl-5 ">
        <h1>Welcome!</h1>
        <p>
          We are so excited to share our florals with&nbsp;you.{' '}
          <br className="d-none d-md-block" />
          Please follow me on instagram to receive notifications about our next
          pop up.
        </p>

        <p>
          Pickup in Oshawa or contactless delivery available to Ajax, Whitby,
          Oshawa, Courtice and&nbsp;Bowmanville
          <br />
        </p>
        <p>
          Follow me on instagram{' '}
          <a
            target="_blank"
            href="https://instagram.com/raes_of_love"
            className="d-flex  align-items-center"
          >
            <Icon name="instagram" />
            raes_of_love
          </a>
        </p>
      </div>

      {/* {posts.map(({ post }, i) => (
     
          <Post
            data={post}
            options={{
              isIndex: true,
            }}
            key={i}
          />
      ))} */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            price
            id
            path
            category
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
                original {
                  src
                }
              }
            }
            gallery {
              childImageSharp {
                fluid(maxWidth: 600, quality: 85) {
                  src
                }
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
